.signup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.signup > h3 {
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 13px;
}
.signup_div {
    width: 100%;
    padding: 2.5% 1.5%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
}
.signup_div > p {
    font-size: 11px;
    text-align: center;
    margin-top: 40px;
    width: 80%;
    color: gray;
}
.signup_form {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.signup_form > h1{
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 50px;
    color: dimgray;
}
.signup_form > div{
    display: flex;
    justify-content: space-between;
}
.signup_form > div > input {
    border: 2px solid lightgray;
    padding: 10px;
    height: 40px;
    border-radius: 2.5px;
    width: 100%
}
.signup_form > div > input:focus {
    outline: 1px solid salmon;
}
.signup_form > div > button{
    width: 100%;
    height: 40px;
    border-radius: 2.5px;
    background-color: #fdbb30;
    border: 0;
    cursor: pointer;
    font-size: 13px;
    letter-spacing: 2.5px;
}
.signup_form > div > button:hover{
    background-color: #f5c763;
}
.signup_form > div > p{
    margin-top: 10px;
    font-size: 14px;
    color: black;
}
.signup_form > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 12.5px;
}
.signup_input {
    border: 1.55px solid lightgray;
    padding: 10px;
    height: 40px;
    border-radius: 2.5px;
    margin-bottom: 10px;
}
.signup_label {
    margin-bottom: 2.5px;
    color: darkgray;
    font-size: 15px;
}
.signup_link {
    text-decoration: none;
    font-weight: 600;
}
@media all and (max-width: 600px ) {
    .signup_div {
        width: 85%
    }
}