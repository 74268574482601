.footer {

}
.footer__section__one {
    padding: 1.5% 22%;
}
.footer__section__one__head {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.footer__section__one__head > span{
    margin-left: auto;
    display: flex;
    align-items: center;
}
.footer__section__one__head__socials {
    font-size: 15px;
    margin-left: 30px;
    font-weight: 500;
    cursor: pointer;
}
.footer__section__one__main {

}
.footer__section__one__main__destinations{
    margin-bottom: 40px;
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid lightgray;
}
.footer__section__one__main__destinations__header{
    font-size: 16.5px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 15px 0;
    cursor: pointer;
}
.footer__section__one__main__destinations__body{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px 0;
}
.footer__section__one__main__destinations__body.clicked{
    display: none;
}
.footer__section__one__main__destinations__body >div{
    width: 25%;
}
.footer__section__one__main__destinations__body > div > p{
    font-size: 13px;
    margin-bottom: 10px;
    color: gray;
}
.footer__section__one__main__foot{
    margin-bottom: 20px;
    display: flex;
    align-items: start;
    width: 100%;
}
.footer__section__one__main__foot > div{
    width: 25%;
}
.footer__section__one__main__foot > div > h1{
    font-size: 16.5px;
    font-weight: 600;
    margin-bottom: 15px;
}
.footer__section__one__main__foot > div > p{
    font-size: 13px;
    margin-bottom: 7.5px;
}
.footer__section__two {
    background-color: #f0f0f0;
    padding: 0.5% 22%;
}
.footer__section__two > p{
    font-size: 12px;
    color: #99adb8;
    margin-bottom: 7.5px;
}

@media all and (max-width: 600px) {
    .footer__section__one {
        padding: 2.5% 5%;
    }
    .footer__section__one__main__destinations__body{
        display: flex;
        align-items: start;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .footer__section__one__main__destinations__body >div{
        width: 50%;
        margin: 30px 0;
    }
    .footer__section__one__main__foot{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .footer__section__one__main__foot > div{
        width: 50%;
        margin-top: 30px;
    }
    .footer__section__one__main__foot > div > h1{
        font-size: 16.5px;
        font-weight: 600;
        margin-bottom: 15px;
    }
    .footer__section__one__main__foot > div > p{
        font-size: 13px;
        margin-bottom: 7.5px;
    }
    .footer__section__two {
        background-color: #f0f0f0;
        padding: 1.5% 5%;
    }
}