.becomeahost {
    background: #fff7eb;
    display: flex;
    justify-content: space-between;
    padding: 2.5% 20%;
    padding-top: 10vh;
}
.becomeahost__hero {
    width: 40%;
}
.becomeahost__hero > h1 {
    font-family: primarySerif, serif;
    font-size: 37.5px;
}
.becomeahost__hero > p{
    font-size: 16px;
    color: gray;
    line-height: 30px;
    margin-top: 25px;
}
.becomeahost__hero > img{
    object-fit: contain;
    width: 100%;
    margin-top: 40px;
}
.becomeahost__form {
    width: 45%;
    padding: 30px 35px;
    background: white;
    height: max-content;
}
.becomeahost__form > form{

}
.becomeahost__form > form > button{
    width: 100%;
    height: 40px;
    border-radius: 2.5px;
    background-color: #fdbb30;
    border: 0;
    cursor: pointer;
    font-size: 13px;
    letter-spacing: 2.5px;
    margin: 25px 0;
}
.becomeahost__form > form > p{
    font-size: 13px;
    color: gray;
    line-height: 25px;
    margin-top: 12.5px;
}
.becomeahost__form > form > span{
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
}
.becomeahost__form > form > span > div{
    width: 100%;
}
.listing__h3 {
    margin: 12.5px 0;
    font-size: 13px;
    font-weight: 600;
    color: gray;
}
.listing__input {
    border: 1px solid lightgray;
    height: 50px;
    border-radius: 2.5px;
    width: 100%;
}
.list__input {
    border: 1px solid lightgray;
    height: 50px;
    border-radius: 2.5px;
    width: 95%;
}

@media all and (max-width: 600px) {
    .becomeahost {
        padding: 5% 5%;
        flex-direction: column;
    }
    .becomeahost__hero {
        width: 100%;
    }
    .becomeahost__form {
        width: 100%;
    }
}
