.head {
    padding: 0.5% 20%;
    display: flex;
    align-items: center;
    box-shadow: 0.5px 0.5px 1.5px -1px #28282b;
}
.head__logo {
    object-fit: contain;
    height: 4vh;
    cursor: pointer;
}
.head__user {
    margin-left: auto;
    display: flex;
    align-items: center;
}
.head__user > button{
    background: white;
    border: 1px solid lightgray;
    color: black;
    padding: 5px 15px;
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.head__user > p{
    font-size: 13px;
    font-weight: 600;
    margin-right: 30px;
}

@media all and (max-width: 600px ) {
    .head {
        padding: 1.5% 5%;
    }
    .head__user > p{
        margin-right: 10px;
    }
}