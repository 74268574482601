.ls__main{
    padding-top: 7vh;
}
.ls__main__section__one {
    padding: 2.5% 20%;
    min-height: 60vh;
    background: #fff7eb;
    display: flex;
    align-self: start;
    justify-content: space-between;
}
.ls__main__section__one__start {
    width: 40%;
}
.ls__main__section__one__start > h1{
    font-family: primarySerif, serif;
    font-size: 37.5px;
}
.ls__main__section__one__start > p{
    font-size: 16px;
    color: gray;
    line-height: 30px;
    margin-top: 25px;
}
.ls__main__section__one__start__img {
    object-fit: contain;
    height: 300px;
    margin-top: 10px;
}
.ls__main__section__one__end {
    width: 40%;
    background: white;
    padding: 25px;
    height: min-content;
}
.ls__main__section__one__end img{
    object-fit: contain;
    width: 100%;
}
.ls__main__section__one__end h3{
    font-size: 14px;
    font-weight: 500;
    line-height: 22.5px;
    text-align: center;
    margin: 20px 0;
}
.ls__main__section__one__end button{
    width: 100%;
    height: 40px;
    border-radius: 2.5px;
    background-color: #fdbb30;
    border: 0;
    cursor: pointer;
    font-size: 13px;
    letter-spacing: 2.5px;
    margin-top: 30px;
}
.ls__main__section__two {
    padding: 2% 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ls__main__section__two > h1{
    font-family: primarySerif, serif;
    font-size: 25px;
}
.ls__main__section__two > h3{
    font-size: 15px;
    font-weight: 500;
    line-height: 22.5px;
    text-align: center;
    margin: 10px 0;
    width: 50%;
}
.ls__main__section__two__flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0px;
}
.ls__main__section__two__flex > div{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ls__main__section__two__flex > div > h3{
    font-size: 15px;
    font-weight: 500;
    line-height: 22.5px;
    text-align: center;
    margin: 10px 0;
}
.ls__main__section__two__flex > div > p{
    font-size: 13px;
    font-weight: 500;
    line-height: 17.5px;
    text-align: center;
}
.ls__main__section__two__flex__icon {
    font-size: 60px;
}
.ls__main__section__three {
    min-height: 40vh;
    background-color: #f3f5f9;
    padding: 2.5% 20%;
}
.ls__main__section__three > h1{
    font-family: primarySerif, serif;
    font-size: 25px;
    margin-bottom: 20px;
}
.ls__main__section__three__faq {
    margin: 10px 0;
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid lightgray;
}
.ls__main__section__three__faq__ques {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 20px 0;
    cursor: pointer;
}
.ls__main__section__three__faq__icon {
    font-size: 18px;
    margin-left: auto;
    transition: 0.5s ease-in-out;
}
.ls__main__section__three__faq__icon.clicked{
    transform: rotate(180deg);
}
.ls__main__section__three__faq__ans {
    transition: 0.5s ease-in-out;
}
.ls__main__section__three__faq__ans.clicked{
    display: none;
}
.ls__main__section__three__faq__ans p {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    width: 80%;
    color: gray;
}

@media all and (max-width: 600px) {
    .ls__main__section__one {
        padding: 2.5% 5%;
        min-height: 60vh;
        background: #fff7eb;
        flex-direction: column;
        align-self: start;
        justify-content: start;
    }
    .ls__main__section__one__start {
        width: 100%;
        padding: 30px 0;
    }
    .ls__main__section__one__end {
        width: 100%;
    }
    .ls__main__section__two {
        padding: 5% 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .ls__main__section__two > h3{
        width: 90%;
    }
    .ls__main__section__two__flex {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .ls__main__section__two__flex > div{
        width: 100%;
        margin-bottom: 30px;
    }
    .ls__main__section__two__flex > div > p{
        width: 85%;
    }
    .ls__main__section__one__start__img {
        display: none;
    }
    .ls__main__section__three {
        padding: 5% 5%;
    }
    .ls__main__section__three__faq__ans p {
        width: 100%;
    }
}