.product {
    width: 100%;
}
.product__section__one {
    padding: 2.5% 16%;
    padding-top: 7vh;
    height: 50vh;
    display: flex;
    justify-content: space-between;
}
.product__section__one > img {
    width: 49.5%;
    object-fit: cover;
}
.product__section__two {
    padding: 1.5% 22%;
    display: flex;
    justify-content: space-between;
}
.product__section__two__start {
    width: 63%;
}
.product__section__two__end {
    width: 30%;
    display: flex;
    flex-direction: column;
}
.product__section__two__end > div{
    height: 150px;
    box-shadow: 0.5px 0.5px 3.5px 1.5px #dedede;
    border-radius: 5px;
    position: sticky;
    top: 100px;
    padding: 10px 15px;
}
.product__section__two__end__button {
    width: 100%;
    height: 40px;
    background-color: #fdbb30;
    border: 0;
    cursor: pointer;
    font-size: 13px;
    margin: 25px 0;
    border-radius: 5px;
}
.product__section__two__end__button:hover{
    background-color: #f5c763;
}
.product__section__three {
    min-height: 50vh;
}
.product__section__two__article1 {
    border-bottom: 0.5px solid lightgray;
}
.product__section__two__article1 > h1{
    font-family: primarySerif, serif;
    font-size: 45px;
    margin-bottom: 20px;
}
.product__section__two__article1 > p{
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 20px;
}
.product__section__two__article2{
    border-bottom: 0.5px solid lightgray;
    padding: 2.5% 0;
}
.product__section__two__article2 > h3{
    font-family: primarySerif, serif;
    font-size: 22.5px;
    margin: 20px 0;
}
.product__section__two__article2 > h4{
    font-size: 15px;
    font-weight: 500;
    margin: 15px 0;
}
.product__section__two__article2__poppty {
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
}
.product__section__two__article2__poppty > div{
    width: 33%;
    margin: 5px 10px;
}
.product__section__two__article2__poppty__img {
    object-fit: cover;
    height: 225px;
    width: 100%;
    border-radius: 2.5px;
}
.product__section__two__article2__poppty > div > h3{
    font-size: 13px;
    color: #7a7a7a;
    font-weight: 500;
    line-height: 25px;
}
.product__section__three {
    padding: 2.5% 20%;
}
.product__section__two__article3{
    border-bottom: 0.5px solid lightgray;
    padding: 2.5% 0;
}
.product__section__two__article3> h3{
    font-family: primarySerif, serif;
    font-size: 22.5px;
    margin: 20px 0;
}
.product__section__two__article3> h4{
    font-size: 15px;
    font-weight: 500;
    margin: 15px 0;
}
.product__section__two__article3__amenities {
    padding: 1% 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.product__section__two__article3__amenities > span{
    width: 45%;
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 15px;
    color: gray;
}
.product__amenities__checkmark {
    color: #ffe4ac;
    font-size: 22.5px;
    margin-right: 25px;
}
.product__section__two__article3__bedrooms {
    padding: 1% 0;
    display: flex;
    flex-direction: column;
}
.product__section__two__article3__bedrooms > span{
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 15px;
    color: gray;
}
.product__bedrooms__icon {
    color: gray;
    font-size: 22.5px;
    margin-right: 25px;
}
.product__section__two__article4 > h3{
    font-family: primarySerif, serif;
    font-size: 22.5px;
    margin: 20px 0;
}
.product__section__two__article4 > h4{
    font-size: 15px;
    font-weight: 500;
    margin: 15px 0;
}
.product__section__two__article4 > ul{
    
}
.product__section__two__article4 > ul > li{
    margin: 15px 0;
    font-size: 14px;
    color: #7a7a7a;
    line-height: 25px;
}

@media all and (max-width: 600px) {
    .product {
        width: 100%;
        overflow: hidden;
    }
    .product__section__one {
        padding: 2.5%;
        padding-top: 7vh;
        height: 40vh;
        overflow-x: scroll;
        width: 200%;
    }
    .product__section__one > img {
        width: 50%;
        object-fit: cover;
        margin-right: 10px;
    }
    .product__section__two {
        padding: 2.5%;
        flex-direction: column;
        justify-content: space-between;
    }
    .product__section__two__start {
        width: 100%;
    }
    .product__section__two__end {
        width: 100%;
    }
    .product__section__two__end > div{
        position: fixed;
        top: 85vh;
        left: 0;
        width: 100%;
        background: white;
    }
    .product__section__three {
        padding: 2.5%;
    }
    .product__section__two__article1 > h1{
        font-size: 35px;
        margin-bottom: 20px;
    }
    .product__section__two__article2__poppty {
        width: 200%;
        overflow-x: scroll;
    }
    .product__section__two__article2__poppty > div{
        width: 66%;
        margin-right: 5px;
    }
}