@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif, 'Courier New', Courier, monospace;
    letter-spacing: 0.5px;
}

@font-face {
    font-family: primarySerif;
    src: url(Classyvogueregular.ttf);
  }

  @font-face {
    font-family: secondaryFont;
    src: url(Qasbyne.otf);
  }