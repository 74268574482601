.home {
}
.home__main__section__one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: url(/src/assets/homepageimage2.png);
    background-size: cover;
    min-height: 47.5vh;
}
.home__main__section__one__hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100%;
}

.home__main__section__one__hero > h1 {
    font-family: primarySerif, serif;
    font-size: 40px;
}
.home__main__section__one__hero > h3 {
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
    color: lightgray;
}
.home__main__section__one__searchbox {
    min-height: 5vh;
    background: white;
    width: 45%;
    margin-top: 30px;
    border-radius: 5px;
    display: flex;
    padding: 10px 15px;
}
.home__main__section__one__searchbox input{
    border: 0;
    border-right: 1px solid #7a7a7a;
    width: 33%;
    padding-left: 25px;
}
.home__main__section__one__searchbox input:last-of-type{
    border: 0;
    border-right: 0;
}
.home__main__section__one__searchbox button{
    margin-left: auto;
    background: #fdbb30;
    border-radius: 20px;
    padding: 5px 22.5px;
    border: 0;
    font-weight: 500;
}
.home__main__section__two {
    padding: 1% 25%;
}
.home__main__section__two > h1{
    font-family: primarySerif, serif;
    font-size: 22.5px;
}
.home__main__section__two > h3{
    font-size: 13px;
    color: #7a7a7a;
    font-weight: 500;
    margin-top: 5px;
}
.home__main__section__two__poppty {
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
    margin-top: 10px;
}
.home__main__section__two__poppty > div {
    width: 24%;
    margin: 5px 10px;
}
.home__main__section__two__poppty__img {
    object-fit: cover;
    height: 275px;
    width: 100%;
    border-radius: 2.5px;
}
.home__main__section__two__poppty > div > h1{
    font-size: 14px;
    font-weight: 600;
    margin-top: 5px;
    color: #7a7a7a;
}
.home__main__section__two__poppty > div > h3{
    font-size: 13px;
    color: #7a7a7a;
    font-weight: 500;
    margin-top: 5px;
}
.home__main__section__three {
    background-color: #f5c763;
    padding: 1.5% 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.home__main__section__three > h1{
    font-family: primarySerif, serif;
    font-size: 35px;
}
.home__main__section__three > h3{
    font-size: 13px;
    font-weight: 500;
}
.home__main__section__three__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}
.home__main__section__three__row > div{
    margin: 10px 20px;
    width: 30%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    text-align: center;
}
.home__main__section__three__row > div > div{
    height: max-content;
}
.home__main__section__three__row > div > h1{
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
    color: black;
}
.home__main__section__three__row > div > p{
    font-size: 13px;
    font-weight: 500;
    margin-top: 15px;
    line-height: 25px;
    color: black;
}
.home__main__section__three__row__icon {
    font-size: 100px;
    color: black;
}
.home__main__section__four {
    padding: 1.5% 5%;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.home__main__section__four__seg1 {
    width: 20%;
}
.home__main__section__four__seg1 > h1{
    font-family: primarySerif, serif;
    font-size: 26.5px;
    line-height: 35px;
    width: 90%;
}
.home__main__section__four__seg1 > p{
    font-size: 14px;
    color: #7a7a7a;
    font-weight: 500;
    margin-top: 10px;
    line-height: 25px;
    width: 80%;
}
.home__main__section__four__seg2 {
    display: flex;
    justify-content: space-between;
    width: 80%;
}
.home__main__section__four__seg2 > div{
    width: 215px;
    display: flex;
    flex-direction: column;
    margin: 0 10px;
}
.home__main__section__four__seg2 > div > h1{
    color: #7a7a7a;
    font-weight: 200;
    font-size: 15px;
    margin: 10px 0;
}
.home__main__section__four__seg2 > div > h3{
    font-weight: 200;
    font-size: 14px;
}
.home__main__section__four__img {
    object-fit: cover;
    height: 275px;
    width: 100%;
    border-radius: 2.5px;
}
.home__main__section__five {
    padding: 2.5% 17.5%;
}
.home__main__section__five > div{
    background: url(../../assets/homeaddlistingimg.png);
    background-size: cover;
    padding: 5% 25%;
}
.home__main__section__five > div > h1{
    font-weight: 500;
    font-size: 25px;
    color: white;
    width: 70%;
    margin-bottom: 30px;
    font-family: primarySerif, serif;
}
.home__main__section__five > div > button{
    background-color: black;
    color: white;
    padding: 8px 20px;
    border: 0;
    border-radius: 2.5px;
    font-size: 500;
    font-size: 14px;
    cursor: pointer;
}
.home__main__section__six {
    background-color: #1a1a1a;
    padding: 1.5% 17.5%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    color: white;
}
.home__main__section__six > h1{
    font-family: primarySerif, serif;
    font-size: 40px;
    font-weight: 600;
}
.home__main__section__six > p{
    font-size: 15px;
    line-height: 25px;
    margin-top: 40px;
    text-align: center;
    width: 60%;
}
.home__main__section__six__bg {
    width: 100%;
    background: transparent;
    overflow: hidden;
    margin-top: 60px;
}
.home__main__section__six__fg {
    background: transparent;
    width: 300%;
    position: relative;
    display: flex;
    animation: primary 20s linear infinite;
}
.home__section__six__reviewimg {
    border-radius: 100%;
    height: 100px;
    width: 100px;
    object-fit: cover;
    margin-bottom: 20px;
}
.primary_one {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 50px;
}
.primary_one > div{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.primary_one > div > p{
    width: 50%;
    font-weight: 500;
    line-height: 25px;
}
.home__main__section__seven {
    padding: 1.5% 5%;
}
.home__main__section__seven > h1{
    font-family: primarySerif, serif;
    font-size: 26.5px;
    line-height: 35px;
    margin-bottom: 20px;
}
.home__main__section__seven > div{
    margin-bottom: 20px;
}
.home__main__section__seven__seg2 {
    display: flex;
    justify-content: space-between;
}
.home__main__section__seven__seg2 > div{
    width: 215px;
    display: flex;
    flex-direction: column;
    margin: 0 10px;
}
.home__main__section__seven__seg2 > div > h1{
    color: #7a7a7a;
    font-weight: 200;
    font-size: 15px;
    margin: 10px 0;
}
.home__main__section__seven__seg2 > div > h3{
    font-weight: 200;
    font-size: 14px;
}
.home__main__section__seven__img {
    object-fit: cover;
    height: 275px;
    width: 100%;
    border-radius: 2.5px;
}

@keyframes primary {
    0% { left: 0; }
    30% { left: 0; }
    33% { left: -100%; }
    63% {left: -100%;}
    66% {left: -200%;}
    100% {left: -200%;}
}
@media all and (max-width: 600px ) {
    .home {
        overflow: hidden;
    }
    .home__main__section__one {
        display: flex;
        padding: 0 5%;
        min-height: 55vh;
        margin-top: 7vh;
    }
    .home__main__section__one__hero > h1 {
        font-size: 27.5px;
    }
    .home__main__section__one__hero > h3 {
        font-size: 15px;
        margin-top: 10px;
        color: lightgray;
        text-align: center;
    }
    .home__main__section__one__searchbox {
        width: 100%;
        margin-top: 30px;
        border-radius: 5px;
        flex-direction: column;
        padding: 10px 15px;
    }
    .home__main__section__one__searchbox input{
        border: 1px solid rgb(230, 230, 230);
        width: 100%;
        padding-left: 0;
        padding: 15px 25px;
        margin: 0;
        margin-bottom: 10px;
    }
    .home__main__section__one__searchbox input:last-of-type{
    }
    .home__main__section__one__searchbox button{
        margin-left: 0;
        border-radius: 5px;
        padding: 15px 25px;
        width: 100%;
    }
    .home__main__section__two {
        padding: 5% 2.5%;
    }
    .home__main__section__two > h1{
        text-align: center;
    }
    .home__main__section__two > h3{
        text-align: center;
    }
    .home__main__section__two__poppty {
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .home__main__section__two__poppty > div {
        width: 49%;
        margin: 15px 0;
    }
    .home__main__section__three {
        padding: 5% 2.5%;
    }
    .home__main__section__three > h1{
        font-size: 27.5px;
    }
    .home__main__section__three__row {
        flex-direction: column;
        justify-content: start;
        align-items: start;
        margin: 0;
    }
    .home__main__section__three__row > div{
        margin: 10px 0;
        width: 100%;
        height: max-content;
        display: flex;
        justify-content: start;
        align-items: center;
        text-align: center;
    }
    .home__main__section__three__row > div > h1{
        font-size: 15px;
        font-weight: 600;
        margin-top: 0;
    }
    .home__main__section__three__row > div > p{
        font-size: 13px;
        margin-top: 5px;
        line-height: 20px;
    }
    .home__main__section__three__row__icon {
        font-size: 75px;
        color: black;
        margin: 0;
    }
    .home__main__section__four {
        padding: 5% 2.5%;
        flex-direction: column;
        justify-content: start;
        width: 100%;
    }
    .home__main__section__four > div{
        width: 100%;
        overflow-x: scroll;
    }
    .home__main__section__four__seg1 {
        width: 100%;
        overflow: scroll;
    }
    .home__main__section__four__seg1 > h1{
        font-family: primarySerif, serif;
        font-size: 26.5px;
        line-height: 35px;
        width: 100%;
        text-align: center;
    }
    .home__main__section__four__seg1 > p{
        font-size: 14px;
        color: #7a7a7a;
        font-weight: 500;
        line-height: 25px;
        text-align: center;
        width: 100%;
    }
    .home__main__section__four__seg2 {
        flex-direction: row;
        justify-content: start;
        width: 600%;
    }
    .home__main__section__four__seg2 > div{
        width: 375px;
        margin: 10px;
    }
    .home__main__section__seven {
        padding: 5% 2.5%;
    }
    .home__main__section__seven > h1{
        text-align: center;
    }
    .home__main__section__seven > div{
        width: 100%;
        overflow-x: scroll;
    }
    .home__main__section__seven__seg2 {
        display: flex;
        flex-direction: row;
        justify-content: start;
        width: 600%;
    }
    .home__main__section__seven__seg2.home__responsive__none {
        display: none;
    }
    .home__main__section__seven__seg2 > div{
        width: 375px;
        display: flex;
        flex-direction: column;
        margin: 10px;
    }
    .home__main__section__five {
        padding: 5% 2.5%;
    }
    .home__main__section__five > div{
        padding: 2.5% 5%;
    }
    .home__main__section__five > div > h1{
        font-weight: 500;
        font-size: 25px;
        color: white;
        width: 100%;
    }
    .home__main__section__five > div > button{
        padding: 10px 25px;
        font-weight: 500;
        cursor: pointer;
    }
    .home__main__section__six {
        padding: 2.5% 2.5%;
    }
    .home__main__section__six > h1{
        font-size: 25px;
        font-weight: 600;
        text-align: center;
    }
    .home__main__section__six > p{
        width: 100%;
    }
    .primary_one > div > p{
        width: 100%;
    }
}