.modal {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: start;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(38, 38, 38, 0.4);
}
.modal__content {
    width: 22.5%;
    margin-top: 15vh;
    padding: 1.5% 1.5%;
    border-radius: 5px;
    background: white;
}
.modal__close {
    cursor: pointer;
    margin-bottom: 0px;
    font-size: 20px;
    color: black;
}

@media all and ( max-width: 600px ) {
    .modal {
        background-color: rgba(38, 38, 38, 0.8);
    }
    .modal__content{
        width: 95%;
        margin-top: 18vh;
    }
}