.header {
    height: 7vh;
    box-shadow: 0.5px 0.5px 1.5px -1px #28282b;
    z-index: 10;
    width: 100%;
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    color: black;
}
.header__logo {
    object-fit: contain;
    height: 5vh;
    cursor: pointer;
}
.home_menu {
    display: none;
    font-size: 30px;
    margin-left: auto;
    z-index: 10;
}
.header__user {
    display: flex;
    align-items: center;
    justify-content: center;
}
.header__user button {
    background: white;
    border: 1px solid lightgray;
    padding: 5px 15px;
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.header__user__icon {
    font-size: 25px;
}
.header__user__icon:last-of-type {
    margin-left: 15px;
    font-size: 25px;
}
.header__user > h3 {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-right: 20px;
    position: absolute;
    right: 150px;
}
.header__nav {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: start;
    margin-left: auto;
    color: black;
    font-size: 13px;
    font-weight: 500;
}
.header__nav__list{
    margin-right: 30px;
    font-size: 13px;
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
    color: black;
}
.header__nav__list > li{
    position: relative;
}

/* submenu level 1 style */

.header__submenu {
    position: absolute;
    background: white;
    width: 180px;
    list-style-type: none;
    display: none;
    border-radius: 2.5px;
}
.header__submenu > li{
    padding: 12.5px 30px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    color: #28282b;
    font-weight: 600;
    display: flex;
    align-items: start;
    border-bottom: 1px solid lightgray;
}

/* submenu level 2 style */

.header__submenu2 {
    position: absolute;
    background: white;
    width: 150px;
    left: 100%;
    list-style-type: none;
    display: none;
    border-radius: 2.5px;
}
.header__submenu2 > li{
    padding: 12.5px 30px;
    font-size: 13px;
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
    border-bottom: 1px solid lightgray;
}

/* show menu on hover */
.header__nav__list:hover > .header__submenu {
    display: block;
    animation: slideup .3s ease;
}
.header__submenu > li:hover > .header__submenu2 {
    display: block;
    animation: slideleft .3s ease;
}

/* animation keyframes */
@keyframes slideup{
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes slideleft {
    0% {
        opacity: 0;
        transform: translateX(10px)
    }
    100% {
        opacitX: 1;
        transform: translateY(0)
    }
}
.header__nav__list:hover{
    color: #fdbb30;
}
.header__home__icon {
    font-size: 20px;
    margin-right: 10px;
}
.home_sidebar{
    position: absolute;
    z-index: 100;
    background-color: black;
    top: 0;
    left: -100%;
    width: 95%;
    margin: 0;
    min-height: 100vh;
    transition: all 0.75s linear;
    
}
.home_sidebar_true{
    position: absolute;
    z-index: 100;
    background-color: black;
    top: 0;
    left: 0;
    width: 95%;
    margin: 0;
    min-height: 100vh;
    transition: all 0.75s linear;
    
}
.header__account {
    /* display: flex;
    flex-direction: column; */
    position: absolute;
}
.header__account ul{
    position: absolute;
}
.header__account:hover .header__submenu {
    display: block;
    animation: slideup .3s ease;
}
.header__submenu.home__account__menu {
    
}
@media all and (max-width: 600px ) {
    .header__nav {
        display: none;
    }
    .header__user > h3 {
        font-size: 12px;
        margin-right: 0px;
        right: 120px;
    }
    .header {
        padding: 0 2.5%;
    }
    .header__user {
        margin-right: 50px;
    }
}