.login {
    width: 100%;
}
.login_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.login_body >  form > h2{
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
    color: dimgray;
}
.login_form {
    display: flex;
    flex-direction: column;
    padding: 25px 15px;
    border-radius: 10px;
    width: 100%;
}
.login_input {
    height: 40px;
    margin: 7.5px 0;
    padding: 15px;
    border: 1.55px solid lightgray;
    border-radius: 2.5px;
}
.login_button {
    width: 100%;
    height: 40px;
    border-radius: 2.5px;
    background-color: #fdbb30;
    border: 0;
    cursor: pointer;
    font-size: 13px;
    margin: 25px 0;
}
.login_button:hover{
    background-color: #f5c763;
    border: 0;
}
.login_text {
    font-size: 13px;
    color: gray;
    margin: 10px 0;
    text-align: center;
}
.login_link {
    margin-left: 10px;
    font-size: 13px;
    font-weight: 600;
    color: gray;
}
.login_link:visited {
    color: gray;
    text-decoration: none;
}
.login_link:active {
    color: gray;
    text-decoration: none;
}

@media all and (max-width: 600px) {
    .login_body {
        width: 100%;
    }
    .login_form {
        width: 95%;
        padding: 25px 15px;

    }
}