.becomeahostsuccess__main {
    min-height: 100vh;
    background: #fff7eb;
}
.becomeahostsuccess {
    display: flex;
    justify-content: center;
}
.becomeahostsuccess > div{
    margin-top: 20vh;
    margin-left: 15%;
}
.becomeahostsuccess > div > h1{
    font-family: 'Nanum Myeongjo', serif;
    font-size: 32.5px;
}
.becomeahostsuccess > div > p{
    font-size: 16px;
    color: gray;
    line-height: 30px;
    margin-top: 15px;
    width: 70%;
}

@media all and (max-width: 600px) {
    .becomeahostsuccess > div > p{
        width: 100%;
    }
    .becomeahostsuccess > div{
        margin: 0 5%;
        margin-top: 20vh;
    }
}